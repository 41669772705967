<template>
    <PlainLayout>
          <template #header>
            <img src="@/assets/layout/images/redirectTo.svg"/> 
          </template>
          <template #content>
              <h1 class="font-bold">¡ESPERA!</h1>
              <h2>Está siendo redireccionado a la aplicación <strong>{{appName.toUpperCase()}}</strong>, esto puede tomar unos segundos.</h2>
            
              <div class="flex">
                     <ProgressSpinner  strokeWidth="8" fill="#EEEEEE"/>
              </div>
          </template>
    </PlainLayout>
</template>

<script>
import PlainLayout from '@/layout/PlainLayout.vue';
import  EmpleadoService from '@/service/empleadoService'

import GeneralService from '@/service/generalService'


export default {
    components: { PlainLayout },
    empleadoService : null,
    generalService : null,
    data() {
          return {
               appName : process.env.VUE_APP_NAME,
          };
    },
    created(){
        this.generalService =  new GeneralService(this.axios)
        this.empleadoService =  new EmpleadoService(this.axios)
    },
    mounted(){
        this.fxInit();
    },
    methods:{
        async fxInit(){
            //Get router parameter
            var keyList = this.$cookies.keys();
            if(keyList){
                var ssoList = undefined;
                await keyList.forEach(async(element) => {
                    var decodedStr = atob(element);
                    if(decodedStr === "ssokey"){
                        var cookieContent =  atob(this.$cookies.get(element))
                        var jsonCookie = JSON.parse(cookieContent)
                        ssoList = jsonCookie
                        return;
                    }else{
                        this.$router.push({name : "Login"})
                    }
                });    
                if(typeof ssoList === 'object'){
                    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + ssoList.token;
                   

                    //param : ssoList (Object) =>  { token : Object , modulos : Array , usuarios :  Object, empleado : Object }
                    await this.$store.dispatch("login",ssoList).then(async () =>{
                        //Set Module
                        this.$store.dispatch("userModule", ssoList.modulos);  //set Modulos
                       
                        //get empleado data.
                        var empleadoId = this.$store.getters.getEmpId
                        if( empleadoId != 0)
                            this.empleadoService.GetById(empleadoId).then(res=>{
                                this.$store.commit("setEmpleado",res);
                        })

                        //set Ciclo
                        var getCiclo = await this.fx_getCicloVigente().then();
                        this.$store.dispatch("cicloActive", getCiclo);
                    });

                    

                     this.$router.push({name : "Login"})
                }
            }else{
                this.$router.push({name : "Home"})
            }
        },

        async fx_getCicloVigente(){
            var cicloList =  await this.generalService.getCiclo();
            var ciclo = null
            //Selected Ciclo Vigente
            if(cicloList.length > 0 ){
                var vigente =  cicloList.findIndex(x=> x.cil_vigente =="S");
                ciclo =  vigente <= -1 ? null : cicloList[vigente]
            }
            return ciclo;
        },
    }
}
</script>